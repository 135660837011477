
import { defineComponent, ref } from 'vue'
import { useRouter } from 'vue-router'
import BaseTable from '@/components/BaseTable.vue'
import ConfirmDialog from '@/components/ConfirmDialog.vue'
import { useDeleteDocument } from '@/composables/useDeleteDocument.ts'
import Button from 'primevue/button.js'
import { useToast } from 'primevue/usetoast.js'
import { db } from '@/services/firebase.ts'
import { useStore } from 'vuex'
import { Patient } from '@/types'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default defineComponent({
  components: { BaseTable, ConfirmDialog, Button, BaseBreadcrumb },
  setup() {
    const patients = ref<Array<Patient>>([])
    const toast = useToast()
    const router = useRouter()
    const store = useStore()
    const {
      disableConfirmButton,
      displayConfirmDelete,
      deleteDocument,
      documentId
    } = useDeleteDocument()
    const columns = ref([
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'active', header: 'Active' }
    ])
    const breadcrumbs = [{ label: 'Patients' }]

    function getPatients() {
      db.ref('patients')
        .once('value')
        .then(data => {
          const patientList: Array<Patient> = []
          data.forEach(function (snapshot) {
            const row: Patient = { ...snapshot.val(), id: snapshot.key }
            patientList.push(row)
          })
          patients.value = patientList
        })
    }

    function updateDocument(params: Record<string, boolean>, msg: string) {
      db.ref('patients/' + documentId.value)
        .update(params)
        .then(() => {
          getPatients()
          displayConfirmDelete.value = false
          disableConfirmButton.value = false
          toast.add({
            severity: 'success',
            summary: 'Success Message',
            detail: msg,
            life: 3000
          })
        })
        .catch(error => {
          toast.add({
            severity: 'error',
            summary: 'Error Message',
            detail: error.message,
            life: 3000
          })
        })
    }

    function confirm() {
      disableConfirmButton.value = true
      updateDocument({ active: false }, 'Patient successfully disabled')
    }

    function display(value: boolean) {
      displayConfirmDelete.value = value
    }

    function goToUpdate(id: string) {
      router.push({
        name: 'PatientUpdate',
        params: { id }
      })
    }

    function enableDocument(id: string) {
      documentId.value = id
      updateDocument({ active: true }, 'Patient successfully enabled')
    }

    getPatients()

    return {
      patients,
      columns,
      displayConfirmDelete,
      disableConfirmButton,
      deleteDocument,
      confirm,
      display,
      goToUpdate,
      enableDocument,
      store,
      breadcrumbs
    }
  }
})
